export const IMAGES = [
  {
    src: "https://i.ibb.co/Gkx0yrQ/Picture2.png",
    thumbnail: "httpst://i.ibb.co/Yc4dyMr/106_thumb.jpg",
  },
  {
    src: "https://i.ibb.co/2KzxXQw/Picture3.png",
    thumbnail: "https://i.ibb.co/6rHKxg8/105_thumb.jpg",
  },
  {
    src: "https://i.ibb.co/ypPbwGz/Picture4.png",
    thumbnail: "https://i.ibb.co/2sTBPdX/102_thumb.jpg",
  },

  {
    src: "https://i.ibb.co/stMVyYD/Picture6.png",
    thumbnail: "https://i.ibb.co/DzywQXn/103_thumb.jpg",
  },
  {
    src: "https://i.ibb.co/ZfPMsxD/Picture7.png",
    thumbnail: "https://i.ibb.co/Q6sSWpY/104_thumb.jpg",
  },
  {
    src: "https://i.ibb.co/DV4s9YV/Picture8.png",
  },
  {
    src: "https://i.ibb.co/GtwWJqD/Picture9.png",
  },
  {
    src: "https://i.ibb.co/j5fs1W6/Picture1.png",
  },
  {
    src: "https://i.ibb.co/TgWRsLz/img1.png",
  },
  {
    src: "https://i.ibb.co/GVLgwYn/img2.png",
  },
  {
    src: "https://i.ibb.co/Bsp1Qnv/img3.png",
  },
  {
    src: "https://i.ibb.co/k8ywSw2/img4.png",
  },
  {
    src: "https://i.ibb.co/31k24f2/img5.png",
  },
  {
    src: "https://i.ibb.co/r6rL34n/img6.png",
  },
  {
    src: "https://i.ibb.co/NVX8Vx6/img7.png",
  },
  {
    src: "https://i.ibb.co/y5FmyBT/img8.png",
  },
  {
    src: "https://i.ibb.co/8XxwDLT/img9.png",
  },
];

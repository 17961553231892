export const IMAGES = [
  { src: "https://i.ibb.co/V9HBBRX/image.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/tH3V03G/1.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/Fmy23nc/2.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/Y3LDgtS/3.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/fCzn2Mj/4.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/Lh1fv0j/5.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/HHY1JsW/Edge-vlsi-lab.png", thumbnail: "" },
  { src: "https://i.ibb.co/8sJd3xZ/7-vlsi-pic.png", thumbnail: "" },
];

export const IMAGES = [
  {
    src: "https://i.ibb.co/p4FytnQ/307-pic1.jpg",
    thumbnail:
      "https://i.ibb.co/p4FytnQ/307-pic1.jpg",
  },
  {
    src: "https://i.ibb.co/MBwMw70/307-pic2.jpg",
    thumbnail:
      "https://i.ibb.co/MBwMw70/307-pic2.jpgk",
  },
];

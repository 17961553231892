export const IMAGES = [
  {
    src: "https://i.ibb.co/tsD6HmS/309-pic1.jpg",
    thumbnail:
      "https://i.ibb.co/tsD6HmS/309-pic1.jpg",
  },
  {
    src: "https://i.ibb.co/vJzH7q3/309-pic2.jpg",
    thumbnail:
      "https://i.ibb.co/vJzH7q3/309-pic2.jpg",
  },
];
